import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"
import ourApproach from "../../../assets/images/blog/our-approach-min.jpg"

export default () => 
<div>
    <SEO title={'How to Lead Enterprise-class AI, IoT and Blockchain Projects | ICON Worldwide'}
    description="AI, IoT, and Blockchain projects aren't just the future, but the present: discover more about how we build such enterprise-class projects!"
    canonical={'https://icon-worldwide.com/blog/how-to-lead-ai-iot-blockchain-projects'} />

    <NewsHeader/>

    <div id="single-news">
        <h1>How to <span>Lead Enterprise</span> Scale AI, IoT and Blockchain Projects</h1>
        <div id="title-underline"></div>
        <h2>Our approach to building AI, Blockchain and IOT together with our clients.</h2>

        <div id="single-news-texts">
            <p>Like just about everyone, we are enthusiastic about the promise of Artificial Intelligence and Blockchain technologies for businesses. Most of our clients, from pharma and manufacturing companies to promising start-ups are talking with us about how to implement projects with these new technologies. Noone wants to miss the huge opportunities presented, yet initiating these projects in the context of traditional project based/scope-or-work/ROI processes is difficult.</p>
            <p>The barrier, of understanding the long-term potential of AI, Blockchain, and IOT technologies, coupled with the challenge of how to proceed concretely is inherent with all transformative technologies. Just, twenty years ago the retail industry saw the potential of internet to transform sales and distribution. It took a long evolution for companies and agencies to deliver on this potential in the online shopping experience we all take for granted today.</p>
            <p>For Blockchain, AI and IOT the path forward will be similar. Already, we are seeing signs that the original excitement of Blockchain is slowing down as companies struggle with how to implement blockchain projects in a practical way within their organisations. (See: <a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.bloombergquint.com/technology/blockchain-once-seen-as-a-corporate-cure-all-suffers-slowdown">Bloomberg: “Blockchain, Once Seen as a Corporate Cure-All, Suffers Slowdown.”</a>)</p>
            <h2>Generally, the challenge is bridging the “reality of today ” with the “promise of tomorrow” – the undefined promise of what may be possible.</h2>
            <p> For example, today, most projects being discussed in the context of AI are actually more traditional “digitalisation” initiatives. Essentially, any project based on a database or an algorithm has AI potential but most will demand a long-term perspective to realise.</p>
            <p>We encourage our clients to take a long-term perspective on AI projects. For example, if the initial execution or scope of work is a classic database or algorithm project, to add “intelligence” a mass amount of data is required. We believe it is important to conceptualise the AI potential early in almost every project in order to ensure that the data collection is optimized from the beginning.</p>
            <p>We believe, with the right perspective, it is possible to make small steps towards validating and testing the potential for AI early in a project. And then continuously optimise and improve over time. For a great perspective on how to make a low-investment start in AI development check out this excellent podcast: <a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.stitcher.com/podcast/spark-london/exponential-view/e/50286364?autoplay=true&refid=asi_twtr">Exponential View Podacast</a>.</p>
            <img src={ourApproach} style={{width:'100%'}} alt="AI IoT and Blockchain projects, ICON Worldwide Blog" title=" ICON Worldwide blog, hAI IoT and Blockchain projects"/>
            <h2>New technology projects require a greater cooperation between clients and agencies which we explain as:</h2>
            <ul>
                <li>CONTINUOUS DEVELOPMENT</li>
                <li>DYNAMIC MILESTONES</li>
                <li>SHARED RISK & REWARD</li>
            </ul>
            <p>Few development companies are working on AI projects in a real and serious way. (I encourage you to challenge concretely the agencies who profess to be experts in AI & Blockchain development). And few individual developers have enough experience to credibly present themselves as experts. There simply have not been enough real projects built and brought to market for anyone to profess expert capabilities. As a result many companies are making big investments in AI, which they later realise are not effective. These investments in poorly defined projects or inexperienced teams are already having a negative impact on companies who were early adaptors. See: <a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="https://www.mckinsey.com/business-functions/mckinsey-analytics/our-insights/ten-red-flags-signaling-your-analytics-program-will-fail?cid=other-eml-shl-mip-mck-oth-1809&hlkid=67df1b5b1e9f4bc79990233afb26e0fc&hctky=10704793&hdpid=c6e84344-7203-477a-a2b7-e33d422b958a">McKinsey: Ten red flags signaling your analytics program will fail</a>.</p>
            <p>AI, Blockchain and IOT are the inherent domain of web development companies. We have the programmers who will end up building these products. While many agencies are passionate about the potential of AI, they struggle with the challenge of building the AI expertise that is required to meet future demands, and how to persuade clients to commit to these projects. See: <a  rel="noopener noreferrer" target="_blank" rel="noopener noreferrer" href="http://adage.com/article/digital/selling-ai/314138/">AdAge: Selling AI</a>.</p>
            <p>In working with our clients we have come to think about an approach in terms of investing in a “Shared Risk, Shared Reward” strategy. This is designed to help clients take the leap into new technologies. We are working in this mode with both large corporations in Pharma and Finance and with exciting start-ups.</p>
            <p>Helping large corporations bring AI or blockchain projects forward is important as they the scale required to realise the full potential of AI or Blockchain. Smaller start-ups, however, are often the ones with the more revolutionary vision we want to explore with them. They may be able to move faster and with agility, but with an undefineable long-term potential.</p>
            <p>By working in a partnership mode, our developers are given the opportunity to develop their own skill-sets in a real project context. Clients benefit from investing in a team of developers who over-deliver in their passion to explore these technologies.</p>
            <h2>New Technologies, Classic Skill-sets:</h2>
            <p>In the context of both large companies and small start-ups, experience on both the client and agency side is more valuable than ever towards steering projects forward successfully. We are recognized as a top Software Development Company on <a  rel="noopener noreferrer" href="https://www.softwaredevelopmentcompany.co/">SoftwareDevelopmentCompany.co</a></p>
            <h3>Brand Development:</h3>
            <p>Too many AI & Blockchain start-ups struggle with classic challenges around brand positioning and messaging. Often this struggle is to bridge today with tomorrow; i.e. where the business is today Vs. the long-term vision and potential. For companies and products in this position, the challenge is a classic brand positioning effort where experienced marketers can guide.</p>
            <h3>User Interface Design:</h3>
            <p>So much of the potential of AI, Blockchain and IOT depends on an effective user experience, including the reliable collection and visualization of data. Here again classical UI/UX <a href="https://www.designrush.com/agency/app-design-companies" target="_blank" rel="noopener noreferrer">design</a> is essential.</p>
            <h3>Project Management:</h3>
            <p>Finally, the principals of classic project management still apply to development of new technology projects. The risk of undisciplined teams, timelines, and budgets is amplified by a lack of real experience with developing on a blockchain, for example. To mitigate this risk, we can consider agile processes, but experienced technology project management remains essential to successful project completion.</p>
            <p>Clear to all is the fresh wind and excitement that AI, Blockchain and IOT have brought to all industries. The future promise is also clear. The challenge is to create a smooth path to begin to realise this potential.</p>
        </div>

        <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
        <h4><Link to="/contact-us" className="cta">BOOK A MEETING NOW</Link></h4>

     

        <NewsFooter previous="iron-deficiency-day-2018" next="icon-bulgaria-nearshore-development"/>
    </div>

    <Footer noScrollbar="true"/>
</div>